import Amplify from 'aws-amplify';
import { AWSConfig } from '../types';

export const configureAmplify: () => void = async () => {
  const configResponse = await fetch('/aws-config.json');
  const config: AWSConfig = await configResponse.json();
  //const config = { cognitoUserPoolId: 'us-east-1_d3ubFo05g', cognitoUserPoolClientId: '5v6g7g9u70siitc5ire3c7d3of' };

  Amplify.configure({
    Auth: {
      region: 'us-east-1',
      userPoolId: config.cognitoUserPoolId,
      userPoolWebClientId: config.cognitoUserPoolClientId,
    },
  });
};
