import {
  Button,
  Container,
  createStyles,
  FormControl,
  Input,
  InputLabel,
  makeStyles,
  Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    loginFormContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    loginForm: {
      border: '1px solid #ebebeb',
      boxShadow: 'rgba(0,0,0,0.14902) 0px 1px 1px 0px,rgba(0,0,0,0.09804) 0px 1px 2px 0px',
    },
    formControl: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '40px',
    },
  }),
);

export const Login: React.FC = () => {
  const [values, setValues] = useState({
    username: '',
    password: '',
  });

  const [error, setError] = useState<string | undefined>(undefined);

  const history = useHistory();

  const handleChange = (prop: string) => (event: ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClose = () => {
    setError(undefined);
  };

  const classes = useStyles();

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    Auth.signIn({ username: values.username, password: values.password })
      .then(() => {
        setError(undefined);
        history.push('/');
      })
      .catch((reason: { message: string }) => {
        setError(reason.message);
      });
  };

  return (
    <Container className={clsx(classes.loginFormContainer)}>
      <form className={clsx(classes.loginForm)} onSubmit={onSubmit}>
        <FormControl required className={clsx(classes.formControl)}>
          <InputLabel htmlFor={'username'}>Username</InputLabel>
          <Input id={'username'} onChange={handleChange('username')} />
        </FormControl>
        <FormControl required className={clsx(classes.formControl)}>
          <InputLabel htmlFor={'password'}>Password</InputLabel>
          <Input id={'password'} type={'password'} onChange={handleChange('password')} />
        </FormControl>
        <FormControl className={clsx(classes.formControl)}>
          <Button variant="contained" color="primary" type="submit">
            Log In
          </Button>
        </FormControl>
      </form>
      <Snackbar
        open={error !== undefined}
        onClose={handleClose}
        autoHideDuration={6000}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <Alert severity={'error'} onClose={handleClose}>
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
};
