import { createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { configureAmplify } from './auth/auth';
import AppNav from './component/appbar/AppNav';
import { Home } from './component/routes/home/Home';
import { Login } from './component/routes/login/Login';

configureAmplify();

const useStyles = makeStyles(() =>
  createStyles({
    App: {
      marginTop: '64px',
    },
  }),
);

const App: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <div className={clsx(classes.App)}>
        <Router>
          <AppNav />
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
      </div>
    </>
  );
};

export default App;
